import { format, parse, isValid } from 'date-fns';
import { enGB } from 'date-fns/locale';

export const stringToISOStringDate = (stringDate: string) => {
  const parsedDate = parse(stringDate, 'P', new Date(), { locale: enGB });
  const isValidDate = isValid(parsedDate);

  if (isValidDate) {
    return format(parsedDate, 'dd/MM/yyyy');
  }

  return format(new Date(), 'dd/MM/yyyy');
};

export const getFormattedDate = (date?: string) =>
  date ? date : format(new Date(), 'dd/MM/yyyy');
