import React, { Component } from 'react';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { notification } from 'antd';
import { AppState } from '../../redux/modules';
import {
  fetchCreativity,
  fetchJobs,
  getPublishedEmergency,
  changeSelection,
  updateElementProp,
  updateTitle,
  updateCreativity,
  deleteCreativity,
  selectCreativitiesById,
  updatePlayers,
  ICreativity,
  hasAllPlayersSelected,
  hasVideosWithoutSourcesSelector,
  Status,
} from '../../redux/modules/creativities';
import { resetError } from '../../redux/modules/creativities/action';
import { IPlayer } from '../../redux/modules/players';

import { Container, Preview } from './styles';

import { Info } from './Info';
import { Canvas } from './Canvas';
import Menu from './Menu';
import { getPlayerUrl, getFormattedDate } from '../../utils';

interface IProps
  extends RouteComponentProps<{ id: string; emergency?: string }> {
  id: string;
  creativity: ICreativity;
  error?: any;
  players: IPlayer[];
  activeSlide: number;
  activeElement: number;
  changeSelection: any;
  fetchCreativity: any;
  fetchEmergency: any;
  fetchJobs: any;
  hasPublishedEmergency: any;
  hasAllPlayersSelected: boolean;
  updateCreativity: any;
  updateElementProp: any;
  updateTitle: any;
  updatePlayers: any;
  hasVideosWithoutSources: boolean;
  deleteCreativity: any;
  intl?: InjectedIntl;
  resetError: any;
  status: Status;
  selected?: boolean | undefined;
  handlePlay?: any;
  endDate?: string;
}

const getId = (props: IProps) => {
  const { id, match } = props;

  return id ? id : (match && match.params && match.params.id) || '';
};

class Editor extends Component<IProps> {
  jobsInterval?: NodeJS.Timeout;

  componentDidMount() {
    this.props.changeSelection('slide', 0);

    this.jobsInterval = global.setInterval(() => {
      if (this.props.hasVideosWithoutSources) {
        this.props.fetchJobs(this.props.match.params.id);
      }
    }, 10000);

    const { match } = this.props;

    return (
      match && match.params && this.props.fetchCreativity(getId(this.props))
    );
  }

  componentWillUnmount() {
    if (this.jobsInterval) {
      clearInterval(this.jobsInterval);
    }
  }

  onSelectSlide = (value: string) => {
    this.props.changeSelection('slide', value);
  };

  handlePlay = () => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.open(getPlayerUrl(this.props.creativity._id));
  };

  render() {
    if (this.props.creativity) {
      const {
        creativity,
        activeSlide,
        activeElement,
        intl,
        error,
        status,
        selected,
      } = this.props;

      const {
        _id,
        format,
        slides,
        name,
        createdAt,
        emergency,
        visibility,
        players,
        startDate,
        endDate,
      } = creativity;

      const formattedStartDate = getFormattedDate(startDate)
      const formattedEndDate = getFormattedDate(endDate);

      const created = moment(createdAt).format('YYYY-MM-DD HH:mm');

      if (error) {
        notification.open({
          message: 'Error',
          className: 'error',
          description: `Error ocurred: ${intl!.formatMessage({ id: error })}`,
          duration: 45,
          onClose: this.props.resetError,
        });
      }

      return (
        <Container>
          <Preview>
            <Info
              created={created}
              status={creativity.status}
              slides={slides}
              activeSlide={activeSlide}
              emergency={emergency}
              onSelectSlide={this.onSelectSlide}
              startDate={formattedStartDate}
              endDate={formattedEndDate}
            />
            <Canvas
              format={format}
              slide={slides && slides[activeSlide]}
              activeElement={activeElement}
            />
          </Preview>
          <Menu
            creativityId={_id || ''}
            creativityTitle={name}
            status={creativity.status}
            updateElementProp={this.props.updateElementProp}
            changeSelection={this.props.changeSelection}
            updateTitle={this.props.updateTitle}
            emergency={emergency}
            visibility={visibility}
            hasPublishedEmergency={this.props.hasPublishedEmergency}
            updateCreativity={this.props.updateCreativity}
            slide={slides && slides[activeSlide]}
            playersCreativity={players || []}
            players={this.props.players || []}
            updatePlayers={this.props.updatePlayers}
            hasAllPlayersSelected={this.props.hasAllPlayersSelected}
            isPublished={status === Status.PUBLISHED}
            deleteCreativity={this.props.deleteCreativity}
            selected={creativity.selected}
            handlePlay={this.handlePlay}
            endDate={formattedEndDate}
            startDate={formattedStartDate}
          />
        </Container>
      );
    }

    return (
      <Container>
        <FormattedMessage id={'editor.loader'} />
      </Container>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({
    creativity:
      props.match &&
      props.match.params &&
      selectCreativitiesById(state, getId(props)),
    error: state.creativities.error,
    activeSlide: state.creativities.activeSlide,
    activeElement: state.creativities.activeElement,
    hasPublishedEmergency: !!getPublishedEmergency(state),
    hasAllPlayersSelected: hasAllPlayersSelected(state, getId(props)),
    players: state.players.all,
    hasVideosWithoutSources: hasVideosWithoutSourcesSelector(
      state,
      getId(props)
    ),
  }),
  {
    fetchCreativity,
    fetchJobs,
    changeSelection,
    updateElementProp,
    updateTitle,
    updateCreativity,
    updatePlayers,
    deleteCreativity,
    resetError,
  }
)(injectIntl<any>(Editor));
